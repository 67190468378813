<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddTypeDialog"
            >+ 新增问题</el-button
          >
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="questionList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="问题id" width="100" aligin="center" prop="id">
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column label="问题名称" aligin="center" prop="questionTitle">
        </el-table-column>
        <!-- 第三列回答 -->
        <el-table-column label="解决方法" aligin="center" prop="solution">
        </el-table-column>
        <!-- 第四列使用对象 -->
        <el-table-column label="使用对象" aligin="center">
          <template slot-scope="scope">
            <div v-if="scope.row.target === 0">消费者C端</div>
            <div v-if="scope.row.target === 1">商户端</div>
          </template>
        </el-table-column>

        <!-- 第五列操作 -->
        <el-table-column label="操作" width="200" aligin="center">
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditQuestionDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeQuestionById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQuestionDialogVisible"
      width="50%"
      @close="clearAddInfo"
    >
      <el-form
        ref="commonQuestionFormListRef"
        :rules="commonQuestionFormListRules"
        :model="commonQuestionFormList"
        label-width="80px"
      >
        <!-- <el-form-item label="使用对象" prop="target">
          <el-select
            v-model="commonQuestionFormList.target"
            placeholder="请选择使用对象"
            disabled
          >
            <el-option
              v-for="item in targetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="问题标题" prop="questionTitle">
          <el-input
            v-model="commonQuestionFormList.questionTitle"
            placeholder="请输入问题标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="解决方法" prop="solution">
          <el-input
            v-model="commonQuestionFormList.solution"
            placeholder="请输入解决方法"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        pageNo: 1,
        limit: 15,
        questionTitle: '',
        questionTypeId: ''
      },
      // 问题列表
      questionList: [],
      // 总数
      total: 0,
      // 标题
      title: '',
      // 新增还是修改
      submitType: '',
      // 修改的id
      questionId: '',
      // 对话框
      addQuestionDialogVisible: false,
      // 新增入参
      commonQuestionFormList: {
        questionTitle: '',
        questionTypeId: undefined,
        solution: '',
        target: undefined
      },
      // 新增入参最终数组
      pushList: [],
      // 选择项
      targetList: [
        {
          id: '0',
          name: '消费者C端'
        },
        {
          id: '1',
          name: '商户端'
        }
      ],
      // 校验
      commonQuestionFormListRules: {
        questionTitle: [
          { required: true, message: '请输入问题标题', trigger: 'blur' }
        ],
        solution: [
          { required: true, message: '请输入解决方法', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 接收id参数
    this.queryInfo.questionTypeId = this.$route.query.id
    this.commonQuestionFormList.questionTypeId = this.$route.query.id
    // 赋值给新增的入参target
    this.commonQuestionFormList.target = this.$route.query.target
    // 请求该类型下的问题列表
    this.getQuestionList()
  },
  methods: {
    // 定义问题列表方法
    async getQuestionList() {
      const { data: res } = await this.$http.get(
        'commonQuestion/commonQuestionList',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('查询问题列表失败')
      }
      // 赋值
      this.questionList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getQuestionList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getQuestionList()
    },
    // 点击新增按钮
    showAddTypeDialog() {
      this.title = '新增问题'
      this.submitType = 'add'
      this.addQuestionDialogVisible = true
    },
    // 提交新增和修改请求
    async submit() {
      this.$refs.commonQuestionFormListRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          this.pushList.push(this.commonQuestionFormList)
          const { data: res } = await this.$http.post(
            'commonQuestion/',
            this.pushList
          )
          if (res.code !== 0) {
            this.pushList = []
            return this.$message.error('添加问题失败')
          }
          this.$message.success('添加问题成功')
          this.getQuestionList()
          this.addQuestionDialogVisible = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `commonQuestion/${this.questionId}`,
            this.commonQuestionFormList
          )
          if (res.code !== 0) {
            this.pushList = []
            return this.$message.error('修改问题失败')
          }
          this.$message.success('修改问题成功')
          this.getQuestionList()
          this.addQuestionDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo() {
      // 清空问题和办法，不能清除type和target
      this.commonQuestionFormList.questionTitle = ''
      this.commonQuestionFormList.solution = ''
      this.pushList = []
      this.getQuestionList()
    },
    // 编辑
    showEditQuestionDialog(row) {
      this.commonQuestionFormList = row
      this.addQuestionDialogVisible = true
      this.title = '修改问题'
      this.submitType = 'edit'
      // 赋值修改的id
      this.questionId = row.id
    },
    // 删除
    async removeQuestionById(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该问题，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`commonQuestion/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除问题失败')
      }
      this.$message.success('删除问题成功')
      this.getQuestionList()
    }
  }
}
</script>

<style lang="less" scoped></style>
